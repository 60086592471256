@import "./fonts.scss";
@import "./variables.scss";
@import "./btn.scss";
@import "./bubbles.scss";
@import "./loader.scss";

* {
    box-sizing: border-box;
}

html, body, .root, .root-router-host {
    height: 100%;
}

.root > div {
    display: flex;
    flex-direction: column;
    height: 100%;
}

[hidden] {
    display: none !important;
}

.file-field-hidden {
    display: none;
}

.app-lang-ru body {
    font-family: "Helvetica-SemiBold", sans-serif;
}

body {
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 16px;
    color: #000;
    background-color: $color-white;
    overflow-x: hidden;
    margin: 0 auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

a {
    text-decoration: none;
}

p {
    line-height: normal;
}

input {
    padding: 0;
    margin: 0;
}

.main-section {
    padding-top: 56px;
}

.container {
    max-width: 1170px;
    padding-left: 25px;
    padding-right: 25px;
    margin-left: auto;
    margin-right: auto;
}

.title-h2 {
    font-size: 48px;
    line-height: 1.5;
}
.title-h3 {
    font-size: 24px;
    line-height: 1.67;
}

/*slider*/
/*slider*/
.slick-list,
.slick-slider {
    position: initial;
    display: block;
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots {
    li {
        position: relative;
        display: inline-block;
        width: 6px;
        height: 6px;
        cursor: pointer;
        border-radius: 50%;
        padding: 0;
        margin: 0 3px;
        transition: 500ms;
    }
    button {
        width: 6px;
        height: 6px;
        cursor: pointer;
        border-radius: 50%;
        padding: 0;
        margin: 0 3px;
        color: transparent;
        border: 0;
        outline: none;
        background: #e0e0e0;
    }
    .slick-active {
        button {
            background: #000;
        }
    }
}

.slick-slide {
    float: left;
    font-size: 0;
}
