.app-lang-ru {
    .contact-link {
        font-family: "Helvetica-Regular", sans-serif;
    }
    .main-page {
        &__text {
            font-family: "Helvetica-Regular", sans-serif;
        }
    }

    .result-page {
        &__content {
            &-container {
                p {
                    font-family: "Helvetica-Regular", sans-serif;
                }
            }
        }
        &__text {
            font-family: "Helvetica-Regular", sans-serif;
        }
        &__collage {
            &-subtitle {
                font-family: "Helvetica-Regular", sans-serif;
            }
        }
    }
    /*share block*/
    .share-container {
        &__title {
            font-family: "Helvetica-Regular", sans-serif;
            &-mob {
                font-family: "Helvetica-Regular", sans-serif;
            }
        }
    }

    .portraits {
        &__title-container {
            p {
                font-family: "Helvetica-Regular",sans-serif;
            }
        }
    }

    .image-warning-tooltip {
        p {
            font-family: "Helvetica-Regular", sans-serif;
        }
    }

    /*form*/
    .form {
        &__subtitle {
            font-family: "Helvetica-Regular", sans-serif;
        }
        &__title-input {
            font-family: "Helvetica-SemiBold", sans-serif;
        }
        &__label {
            font-family: "Helvetica-Regular", sans-serif;
        }
        &__input {
            font-family: "Helvetica-SemiBold", sans-serif;
        }
        &__email {
            &-error {
                &-message {
                    font-family: "Helvetica-Regular", sans-serif;
                }
            }
        }
        &__preview {
            &-btn {
                &-value {
                    font-family: "Helvetica-SemiBold", sans-serif;
                }
            }
        }
    }

    .tooltip {
        &__text {
            font-family: "Helvetica-Regular", sans-serif;
        }
    }

    .modal {
        &__text {
            font-family: "Helvetica-Regular", sans-serif;
        }
        &__set-email {
            .modal__types-title {
                font-family: "Helvetica-SemiBold", sans-serif;
            }
        }
    }

    .manage-page {
        &__text {
            font-family: "Helvetica-Regular", sans-serif;
        }
        &__slide {
            .slide-caption-text {
                font-family: "Helvetica-Regular", sans-serif;
            }
            .slide-caption {
                span {
                    font-family: "Helvetica-Regular", sans-serif;
                    &:last-child {
                        font-family: "Helvetica-SemiBold", sans-serif;
                    }
                    span {
                        font-family: "Helvetica-SemiBold", sans-serif;
                    }
                }
            }
            .slide-tooltip {
                p {
                    font-family: "Helvetica-Regular", sans-serif;
                }
            }
        
        }
    }
    
    .pending-block {
        &__title {
            font-family: "Helvetica-Regular", sans-serif;
        }
    }
    
    /*copied-block*/
    .copied-block {
        &__text {
            font-family: "Helvetica-Regular", sans-serif;
        }
    }

    /*attach-page*/
    .attach-page {
        &.attach-result {
            p {
                font-family: "Helvetica-Regular", sans-serif;
            }
        }
    }

    .manage-container {
        .manage-container__text {
            font-family: "Helvetica-Regular", sans-serif;
        }
        a {
            font-family: "Helvetica-SemiBold", sans-serif;
        }
    }

    /*choose-body*/
    .choose-body {
        .btn-select {
            font-family: "Helvetica-SemiBold", sans-serif;
        }
        .error-container {
            button {
                font-family: "Helvetica-SemiBold",sans-serif;
            }
        }
    }

    .choose-body-btns {
        .active {
            font-family: "Helvetica-SemiBold", sans-serif;
        }
    }
}

.logo {
    font-size: 24px;
    line-height: 1;
    color: $color-black;
    display: inline-flex;
    position: relative;
    z-index: 4;

    span {
        color: #dd4044;
    }
}

a.logo {
    cursor: pointer;
    &:hover {
        opacity: 1;
        text-shadow: 0 0 30px $color-blue-dark;
    }
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14.5vh;
}

.contact-link {
    font-family: "Poppins-Regular",sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #3758d6;
    letter-spacing: 0.96px;
    line-height: 1.67;
    text-transform: uppercase;
    @media all and (max-width: 450px) {
        font-size: 16px;
        line-height: 1.5;
    }
}

/*main page*/
.main-page {
    position: relative;
    overflow: hidden;
    height: 100%;
    padding-bottom: 9.7vh;
    .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .logo {
        margin: 0;
    }
    &__content {
        flex-grow: 1;
        max-width: 640px;
        @media all and (max-width: 1140px) {
            width: 50%;
        }
    }
    &__title {
        margin-bottom: 24px;
    }
    &__text {
        font-family: "Poppins-Regular", sans-serif;
        font-size: 24px;
        line-height: 1.67;
        margin-bottom: 64px;
    }
    &__bg {
        max-width: 1050px;
        width: 100%;
        position: absolute;
        top: 72px;
        left: 60%;
        transform: translateX(-50%);
        z-index: -5;
    }
   
    &2 {
        overflow: auto;
        .main-page__content-container {
            display: flex;
            justify-content: space-between;
        }
        .main-page__content {
            width: 50%;
            flex-shrink: 0;
        }
        .main-page__bg {
            position: static;
            width: 100%;
            transform: none;
            margin-top: -110px;
        }
    }
    &3 {
        .main-page__bg {
            max-width: 656px;
            margin-top: -100px;
        }
    }
    &__bubble1 {
        top: 140px;
        left: -70px;
        background-color: $color-pink;
    }
    &__bubble2 {
        top: 86px;
        left: 22%;
        background-color: $color-blue-dark;
    }
    &__bubble3 {
        bottom: 24px;
        left: 45%;
        background-color: $color-green;
    }
    &__bubble4 {
        top: 168px;
        left: 62%;
        background-color: $color-pink;
    }
    &__bubble5 {
        bottom: 176px;
        left: 86%;
        background-color: $color-blue-dark;
    }
    @media all and (max-width: 991px) {
        &-slider .logo-container {
            margin-bottom: 24px;
        }
        &-slider .logo {
            margin-bottom: 0;
        }
        &-slider .main-page__container {
            display: flex;
            flex-direction: column-reverse;
        }
        &-slider .main-page__content {
            width: 100%;
            max-width: 100%;
        }
        &-slider .main-page__title {
            font-size: 24px;
            margin-bottom: 8px;
        }
        &-slider .main-page__text {
            font-size: 15px;
            line-height: 1.5;
            margin-bottom: 12px;
        }
        &-slider .btn_width-big {
            font-size: 16px;
            max-width: 280px;
            height: 48px;
            padding: 0;
            margin: 0 auto;
        }
        &-slider .main-slider {
            position: relative;
            top: 0;
            transform: none;
            margin-bottom: 12px;
            max-width: 100%;
           .slick-list {
                height: auto !important;
                margin: 0;
            }
            img {
                max-height: 280px;
            }
            .slick-dots {
                bottom: 0px;
            }
        }
    }
    @media all and (max-width: 450px) {
        &-slider .main-slider {
            left: -20px;
            width: calc(100% + 40px);
            max-width: calc(100% + 40px);
            margin-bottom: -15px;
            margin-top: -20px;
            img {
                max-height: 220px;
            }
            .slick-dots {
                bottom: 30px;
            }        
        }
        &-slider .btn_width-big {
            order: -1;
            margin-bottom: 10px;
        }
        .main-page.main-page-slider .main-page__text {
            margin-bottom: 0;
        }
    }
    @media all and (max-height: 600px) and (orientation: landscape) {
        overflow: auto;
    }
    @media all and (max-height: 560px) {
        .main-page-slider .main-slider img {
            max-height: 150px;
        }
    }
}

.main-page__video-view {
    overflow: auto;
    .container {
        height: auto;
    }
    .main-page__video-view-container {
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 24px;
    }
    .logo-container {
        margin-bottom: 20px;
    }
    video {
        width: 100%;
        height: 70%;
        max-width: 500px;
        flex-grow: 1;
        margin: 0 auto 20px;
    }
    .main-page__text {
        margin-bottom: 0;
    }
    .btn {
        flex-shrink: 0;
        margin: 0 auto;
    }

    @media all and (min-width: 820px) and (orientation: landscape) {
        .main-page__video-view-container {
            height: 70vh;
        }
        video {
            height: 60%;
        }
    }
    @media all and (max-width: 819px) and (orientation: landscape) {
        .main-page__video-view-container {
            height: auto;
        }
        video {
            width: 24%;
        }
    }
}

.main-slider {
    max-width: 500px;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    box-sizing: content-box;
    img {
        max-height: 480px;
        border-radius: 12px;
        display: block;
        margin: 0 auto;
    }
    .slick-list {
        height: 100% !important;
        margin: -30px 0;
    }
    .slick-slide {
        margin: 30px 0;
    }
    .slick-active {
        transform: rotate(-2deg);
        transition: .6s;
        button {
            background-color: #fff;
        }
    }
    .slick-dots {
        bottom: 10px;
        li {
            position: relative;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            transition: .5s;
            margin: 0 4px;
        }
        .slick-active {
            width: 42px;
        }
        button {
            background-color: #fff;
            border-radius: 3px;
            width: 100%;
            height: 100%;
        }
    }
}

/*result-page*/
.result-page {
    .logo {
        margin-bottom: 48px;
    }
    &__content {
        display: flex;
        justify-content: space-between;
        &-container {
            margin-bottom: 56px;
            .title-h3 {
                margin-bottom: 16px;
            }
            p {
                font-family: "Poppins", sans-serif;
                line-height: 1.75;
                color: #666;
                margin-bottom: 22px;
            }
        }
        &.guest {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, auto);
            .result-page__column_1-1 {
                grid-row: 1/2;
                grid-column: 1/2;
            }
            .result-page__column_1-2 {
                grid-row: 2/3;
                grid-column: 1/2;
            }
            .result-page__column_2 {
                grid-row: 1/3;
                grid-column: 2/3;
                align-items: flex-end;
                max-width: 100%;
                margin-left: 0;
                & > div {
                    width: 100%;
                    max-width: 480px;
                }
            }
        }
    }
    &__column {
        &_1 {
            max-width: 600px;
            width: 100%;
            padding-right: 16px;
            margin-top: -14px;
            .result-page__action-btn {
                display: inline-flex;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-color: #3758d6;
                position: relative;
                padding: 0;
                margin: 0;
                margin-right: 40px;
                svg {
                    fill: #fff;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    margin: 0;
                }
                &::before {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 40px;
                    background-color: #cdcdcd;
                    position: absolute;
                    right: -20px;
                }
            }
        }
        &_2 {
            flex-shrink: 0;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            max-width: 480px;
        }
    }
    &__title {
        margin-bottom: 24px;
    }
    &__text {
        font-family: "Poppins-Regular", sans-serif;
        font-size: 24px;
        line-height: 1.67;
        margin-top: 24px;
        margin-bottom: 24px;
    }
    &__collage {
        max-width: 480px;
        width: 100%;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 16px;
        &-title {
            font-size: 24px;
            line-height: 1;
            margin-bottom: 8px;
        }
        &-subtitle {
            font-family: "Poppins", sans-serif;
            line-height: 1;
            color: #666666;
            margin-bottom: 24px;
        }
    }
    &__action-btns {
        width: calc(100% - 40px);
        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }
        &_guest {
            & > div {
                justify-content: flex-end;
            }
        }

        .btn {
            width: 48%;
            margin: 0 1% 16px;
        }
    }

    &__moderate-btn {
        width: 45%;
        margin: 0 2%;
    }

    &__action-btn {
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: auto;
        padding: 5px 0;
        margin: 16px auto 0;
    }
    &__action-btn-icon {
        width: 16px;
        flex-shrink: 0;
        margin-left: 10px;
        margin-right: 10px;
    }

    &-btn_upload-collage-owner {
        margin-bottom: 24px;
    }

    .manage-page {
        &__slider {
            left: 0;
            .slick-next {
                left: calc(50% - 50px);
            }
            .slick-prev {
                left: -8px;
            }
            .slick-current {
                opacity: 1;
            }
            .slick-active + .slick-active {
                opacity: .3;
            }
        }
        &__slide {
            .slide-tooltip {
                left: auto;
                right: -8px;
            }
        }
    }

    .logo-container {
        margin-bottom: 48px;

        @media all and (max-width: 768px) {
            margin-bottom: 40px;
            .logo {
                margin-bottom: 0;
            }
        }

        @media all and (max-width: 450px) {
            margin-bottom: 24px;
            .logo {
                margin-bottom: 0;
            }
        }
    }
}

/*share block*/
.share-container {
    &__title {
        font-family: "Poppins-Regular", sans-serif;
        font-size: 24px;
        line-height: 1;
        text-align: left;
        color: $color-grey-dark;
        margin-bottom: 16px;
        &-mob {
            font-family: "Poppins-Regular", sans-serif;
            font-size: 24px;
            line-height: 1;
            text-align: left;
            color: $color-grey-dark;
            margin-bottom: 16px;
            display: none;
        }
    }
    &__btn {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-right: 16px;
    }
    &__btn-icon {
        width: 100%;
        height: 100%;
    }
    &_small {
        .share-container {
            &__title {
                font-size: 16px;
                text-align: right;
                margin-bottom: 8px;
            }
            &__btn {
                width: 56px;
                height: 56px;
                margin-left: 8px;
                margin-right: 0;
                &-download-small {
                    position: relative;
                    margin-right: 32px;
                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        right: -20px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1px;
                        height: 56px;
                        background-color: #cdcdcd;
                    }
                }
            }
        }
    }
    &__btn-download {
        font-size: 24px;
        margin-bottom: 40px;
        svg {
            width: 24px;
            margin-right: 8px;
        }
    }
}

/*create page*/
.create-page {
    padding-bottom: 56px;
    &-1 {
        .form__column-info {
            width: 100%;
        }
    }
    .logo-container {
        margin-bottom: 48px;

        @media all and (max-width: 768px) {
            margin-bottom: 40px;
            .logo {
                margin-bottom: 0;
            }
        }

        @media all and (max-width: 450px) {
            margin-bottom: 24px;
            .logo {
                margin-bottom: 0;
            }
        }
    }
}

.portraits {
    &__container {
        display: flex;
        justify-content: space-between;
        .btn {
            margin-top: 16px;
        }
    }
    &_preview {
        .logo {
            margin-bottom: 40px;
        }
        .portraits__title-container {
            flex-direction: column;
            margin-bottom: 24px;
        }
        .btn {
            margin-top: 16px;
        }
        .portraits__container {
            align-items: flex-start;
        }
    }
    &__title-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 56px;
        p {
            font-family: "Poppins",sans-serif;
            font-size: 24px;
            line-height: 1.67;
            margin-bottom: 40px;
        }
    }
    &__title {
        max-width: 600px;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        width: 262px;
        height: 262px;
        border-radius: 12px;
        border: solid 1px $color-grey;
        overflow: hidden;
        margin: 0 24px 24px 0;
        &:nth-child(4n) {
            margin-right: 0;
        }
        &_active {
            box-shadow: 0 4px 30px 0 $box-shadow-color;
            border: solid 8px $color-blue-dark;
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
        &-container {
            position: relative;
        }
    }
    &__preview-image {
        max-width: 480px;
        max-height: 30vh;
        width: 100%;
        border-radius: 12px;
        object-fit: contain;
    }
    &__buttons-container {
        width: 100%;
    }
}

.image-warning-tooltip {
    background-color: #fbe200;
    border-radius: 12px;
    max-width: 400px;
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 16px;
    margin-top: 14px;
    h3 {
        line-height: 1.5;
        margin-bottom: 4px;
    }
    p {
        font-family: "Poppins", sans-serif;
        line-height: 1.5;
        max-width: 280px;
        span {
            font-weight: 600;
            font-style: italic;
        }
    }
    button {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 12px;
        height: 12px;
        padding: 3px;
        box-sizing: content-box;
        display: flex;
        background: none;
    }
    .triangle {
        background-color: #fbe200;
    }
    .triangle:before,
    .triangle:after {
        content: '';
        position: absolute;
        background-color: inherit;
    }
    .triangle,
    .triangle:before,
    .triangle:after {
        width:  14px;
        height: 14px;
        border-top-right-radius: 6px;
    }
    
    .triangle {
        position: absolute;
        top: -12px;
        left: 50%;
        transform: translateX(-50%) rotate(-60deg) skewX(-30deg) scale(1, 0.866);
    }
    .triangle:before {
        transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
    }
    .triangle:after {
        transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
    }
}
.collage-type {
    &__title {
        margin-bottom: 24px;
    }
    &__list {
        display: flex;
        margin-bottom: 40px;
    }
    &__item {
        width: 262px;
        border-radius: 20px;
        background-color: $color-green;
        background-size: cover;
        cursor: pointer;
        margin-right: 24px;
        &::before {
            content: "";
            display: block;
            padding-top: 100%;
        }
        &_check {
            box-shadow: 0 4px 30px 0 $box-shadow-color;
            border: solid 8px $color-blue-dark;
        }
        &--vector {
            background-image: url("/assets/img/collages-styles/vector.jpg");
        }
        &--vector_body {
            background-image: url("/assets/img/collages-styles/vector_body.jpg");
        }
        &--cartoon_vector_body {
            background-image: url("/assets/img/collages-styles/cartoon_vector_body.jpg");
        }
        &--vector_tile {
            background-image: url("/assets/img/collages-styles/vector_tile.jpg");
        }
        &--cartoon {
            background-image: url("/assets/img/collages-styles/cartoon.jpg");
        }
        &--disney_3d {
            background-image: url("/assets/img/collages-styles/disney_3d.jpg");
        }
    }
}

/*form*/
.form {
    margin-bottom: 0;
    &__column {
        display: flex;
        flex-direction: column;
        &_first {
            margin-bottom: 28px;
        }
        &_second {
            flex-shrink: 0;
            width: 100%;
        }
        &-container {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
        &-info {
            width: 45%;
            padding-top: 0;
        }
    }
    &__title-block {
        display: flex;
        align-items: flex-start;
        position: relative;
    }
    &__title {
        max-width: 600px;
        margin-bottom: 24px;
        &-highlight {
            color: $color-blue-dark;
        }
    }
    &__tooltip-container {
        position: relative;
        top: 10px;
    }
    &__tooltip-btn {
        font-size: 24px;
        color: $color-white;
        width: 40px;
        height: 40px;
        border-radius: 6px;
        background-color: $color-blue-dark;
        position: relative;
        top: -12px;
        left: 16px;
    }
    &__subtitle {
        font-family: "Poppins-Regular", sans-serif;
        font-size: 24px;
        line-height: 1.5;
        text-align: left;
    }
    &__title-input {
        font-family: "Poppins-SemiBold", sans-serif;
        line-height: 1.5;
        text-align: left;
        color: $color-black;
        margin-bottom: 8px;
    }
    &__type {
        &-container {
            margin-bottom: 40px;
        }
        &-title {
            margin-bottom: 16px;
        }
        &-block {
            display: flex;
            flex-direction: column;
            margin-bottom: 42px;
        }
    }
    &__radio {
        position: absolute;
        z-index: -1;
        opacity: 0;
        &:checked + {
            .form__label-name {
                &:after {
                    content: '';
                }
                &:before {
                    background-color: $color-black;
                }
            }
        }
    }
    &__label {
        font-family: "Poppins-Regular", sans-serif;
        cursor: pointer;
        &:first-child {
            margin-bottom: 8px;
        }
        &-name {
            font-size: 24px;
            line-height: 1;
            color: $color-black;
            position: relative;
            display: flex;
            align-items: center;
            &:before {
                content: '';
                display: block;
                width: 40px;
                height: 40px;
                background-color: $color-grey-dark;
                flex-shrink: 0;
                border-radius: 50%;
                margin-right: 8px;
            }
            &:after {
                content: none;
                display: block;
                width: 7px;
                height: 16px;
                border: solid $color-white;
                border-width: 0 2px 2px 0;
                position: absolute;
                left: 16px;
                top: 48%;
                transform: translateY(-50%) rotate(45deg);
            }
        }
        &-subname {
            line-height: 1.5;
            color: $color-grey-dark;
            margin-left: 48px;
        }
    }
    &__input {
        font-family: "Poppins-SemiBold", sans-serif;
        font-size: 24px;
        border-radius: 12px;
        border: solid 2px $color-grey-dark;
        height: 80px;
        line-height: 80px;
        width: 100%;
        line-height: 3.5;
        letter-spacing: 0.48px;
        padding: 0 24px;
        &-container {
            position: relative;
            margin-bottom: 42px;
        }
    }
    &__email {
        width: 100%;
        max-width: 548px;
        &-container {
            position: relative;
            margin-bottom: 40px;
        }
        &-error {
            .form__email {
                border-color: $color-error;
                &-title {
                    color: $color-error;
                }
            }
            &-message {
                font-family: "Poppins-Regular", sans-serif;
                line-height: 1;
                color: $color-error;
                position: absolute;
                top: 88px;
                left: 0;
            }
        }
    }
    &__number {
        text-align: center;
        width: 80px;
        padding: 0 6px;
        margin-right: 8px;
        &-block {
            display: flex;
        }
        &-btn-block {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    &__container {
        display: flex;
        align-items: flex-start;
        margin-bottom: 40px;
    }
    &__preview {
        margin-left: 40px;
        &-btn {
            color: $color-blue-dark;
            display: flex;
            align-items: center;
            background-color: transparent;
            margin-bottom: 12px;
            &_show {
                .form__preview-btn-icon {
                    transform: rotate(90deg);
                }
            }
            &-icon {
                width: 16px;
                height: 16px;
                transition: .6s;
                margin-right: 12px;
            }
            &-value {
                font-family: "Poppins-SemiBold", sans-serif;
                line-height: 1.5;
                letter-spacing: 0.64px;
                text-transform: uppercase;
            }
        }
        &-image {
            width: 100%;
            height: auto;
            object-fit: cover;
            object-position: bottom;
            &-container {
                width: 325px;
                height: 0;
                border-radius: 12px;
                overflow: hidden;
                transition: .6s;
                &_show {
                    height: auto;
                }
            }
        }
    }
    &__avatar {
        &-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 45%;
            background-color: #f6f6f6;
            border-radius: 20px;
            padding-top: 28px;
            padding-bottom: 40px;
            .btn {
                max-width: 264px;
                width: 100%;
            }
        }
        &-title {
            width: 262px;
        }
        &-block {
            width: 262px;
            height: 262px;
            border-radius: 12px;
            overflow: hidden;
            flex-shrink: 0;
            margin-bottom: 24px;
        }
        &-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top center;
        }
    }
}

/*tooltip*/
.tooltip {
    border-radius: 12px;
    box-shadow: 0 4px 30px 0 $box-shadow-color;
    background-color: $color-white;
    width: 331px;
    padding: 16px;
    position: absolute;
    right: -354px;
    top: 0;
    z-index: 100;
    &__title {
        line-height: 1.5;
        margin-bottom: 8px;
    }
    &__text {
        font-family: "Poppins-Regular", sans-serif;
        line-height: 1.5;
    }
    &__btn-close {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 16px;
        height: 16px;
        background-color: transparent;
        &:hover {
            opacity: .6;
        }
        &:before, 
        &:after {
            content: ' ';
            position: absolute;
            top: -2px;
            left: 7px;    
            height: 20px;
            width: 2px;
            background-color: $color-grey-dark;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
    &__triangle {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        left: -15px;
        overflow: hidden;
        transform: translateY(50%) rotate(60deg) skewY(30deg) scaleX(0.866);
        pointer-events: none;
        border-radius: 20%;
        &::before, 
        &::after {
            content: '';
            position: absolute;
            background-color: $color-white;
            pointer-events: auto;
            width: 30px;
            height: 30px;
        }
        &::before {
            transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(0.866) translateX(-24%);
            border-radius: 20% 20% 20% 55%;
        }
        &::after {
            transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(0.866) translateX(24%);
            border-radius: 20% 20% 55% 20%;
        }
    }
}

.invite-container {
    margin-bottom: 16px;
}

.-show-popup {
    overflow: hidden;
}

/*modal*/
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    &__container {
        max-width: 800px;
        width: 90%;
        height: 100%;
        max-height: 80vh;
        overflow: hidden;
        border-radius: 16px;
        box-shadow: 0 8px 60px 0 $box-shadow-color;
        background-color: $color-white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 10;
        padding: 40px;
    }
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        &-container {
            width: calc(100% + 20px);
            position: relative;
            left: 10px;
            padding-right: 20px;
            overflow: auto;
            height: 100%
        }
    }
    &__buttons_container {
        display: flex;
        justify-content: space-around;
        width: 100%;
        flex-shrink: 0
    }
    &__collage {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 16px;

        &-item {
            position: relative;
            width: 256px;
            margin: 0 5px;
            &:after {
                content: '';
                display: block;
                width: 100%;
                padding-top: 100%;
            }
            .imageview-host {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    &__image {
        margin-bottom: 40px;
        &_desktop {
            max-width: 594px;
        }
        &_mob {
            display: none;
            width: calc(100% + 32px);
        }
    }
    &__title {
        text-align: center;
        margin-bottom: 8px;
    }
    &__text {
        font-family: "Poppins-Regular", sans-serif;
        font-size: 24px;
        text-align: center;
        line-height: 1.67;
        margin-bottom: 40px;
    }
    &__bubble1 {
        left: 42px;
        top: 48px;
        background-color: $color-pink;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
    }
    @supports (backdrop-filter: none) {
        &:after {
            background-color: rgba(255, 255, 255, 0.6);
            backdrop-filter: blur(10px);
        }
    }
    
    @supports not (backdrop-filter: none) {
        &:after {
            background-image: url("/assets/img/bg/bg-white.png");
            opacity: .6;
            filter: blur(10px);
        }
    }
    &__set-email {
        .form__label-subname {
            display: block;
            margin-left: 48px;
        }
        .modal__title {
            text-align: left;
        }
        .modal__text {
            text-align: left;
        }
        .modal__types-title {
            font-family: "Poppins-SemiBold", sans-serif;
            font-size: 14px;
            line-height: 1.43;
            margin-bottom: 16px;
        }
        @media all and (max-width: 450px) {
            .modal__title {
                margin-bottom: 4px;
            }
            .modal__text {
                margin-bottom: 16px;
            }
            .form__email-error-message {
                top: 54px;
                line-height: 1.2;
            }
            .form__label-subname {
                line-height: 1.3;
            }
            .modal__buttons_container {
                .btn {
                    font-size: 18px;
                    height: 56px;
                }
            }
        }
    }
    .btn-close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
        padding: 4px;
        display: flex;
        background-color: transparent;
    }
}

@media all and (max-height: 600px) and (orientation: landscape) {
    .modal__container {
        max-height: 96vh;
        overflow: auto;
    }
    .modal__content {
        height: auto;
    }
    .choose-body-content .creative {
        width: 100%;
    }
}

/*manage-page*/
.manage-page {
    padding-bottom: 56px;
    .logo {
        margin-bottom: 56px;
    }
    &__btns-container {
        display: flex;
        justify-content: space-between;
    }
    &__btn-download {
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
    }
    &__preview {
        display: flex;
        justify-content: space-between;
        margin-bottom: 56px;
    }
    &__title {
        margin-bottom: 8px;
        &-block {
            max-width: 640px;
        }
    }
    &__text {
        font-family: "Poppins-Regular", sans-serif;
        font-size: 24px;
        line-height: 1.67;
    }
    &__btns-block {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        &-create {
            margin-bottom: 16px;
            .btn {
                margin-left: 16px;
            }
        }
    }
    &__content {
        display: flex;
        .caption-form {
            width: calc(100% - 40px);
        }
    }
    &__collage-block {
        max-width: 520px;
        width: 100%;
        flex-shrink: 0;
        &-processing {
            width: 100%;
            margin-bottom: 24px;
        }
        .imageview-host {
            border-radius: 12px;
            overflow: hidden;
        }
    }
    &__slider {
        position: relative;
        top: 0;
        right: 100%;
        width: 200%;
        margin-left: -20px;
        .slick-list {
            padding-bottom: 40px;
        }
        .slick-slide {
            opacity: 0.3;
        }
        .slick-active + .slick-active {
            opacity: 1;
        }
        .slick-arrow {
            font-size: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 56px;
            height: 56px;
            border-radius: 50%;
            box-shadow: 0 4px 15px 0 rgba(35,63,169, .45);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            cursor: pointer;
            background-color: #fff;
            background-size: 24px;
            background-repeat: no-repeat;
            background-position: center;
        }
        .slick-next {
            right: -8px;
            background-image: url("/assets/img/icons/arrow-next.png");
        }
        .slick-prev {
            left: calc(50% - 8px);
            background-image: url("/assets/img/icons/arrow-prev.png");
        }
        .slick-disabled {
            display: none !important;
        }
    }
    &__collage-block-processing {
        .manage-page__slide {
            position: relative;
             &:after {
                content: '';
                padding-top: 100%;
                display: block;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                object-fit: contain;
                padding: 0 20px;
            }
        }
    }
    &__slide {
        position: relative;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
        // &:after {
        //     content: '';
        //     padding-top: 100%;
        //     display: block;
        // }
        img {
            width: 100%;
            border-radius: 12px;
            // position: absolute;
            // top: 0;
            // left: 0;
        }
        // .empty-slide {
        //     padding-top: 100%;
        // }
        .slide-caption-text {
            font-family: "Poppins-Regular", sans-serif;
            font-size: 14px;
            color: #666;
            line-height: 1.43;
            text-align: center;
            position: absolute;
            bottom: -38px;
            left: 0;
            right: 0;
            margin: 0;
        }
        .slide-caption {
            font-size: 16px;
            line-height: 1.5;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            width: calc(100% - 40px);
            position: absolute;
            bottom: -40px;
            left: 20px;
            span {
                font-family: "Poppins-Regular", sans-serif;
                &:last-child {
                    font-family: "Poppins-SemiBold", sans-serif;
                    margin-left: 4px;
                }
                span {
                    font-family: "Poppins-SemiBold", sans-serif;
                }
            }
            svg {
                width: 16px;
                margin: 0 16px;
            }
        }
        .slide-tooltip {
            display: flex;
            align-items: center;
            max-width: 400px;
            width: 100%;
            border-radius: 12px;
            box-shadow: 0 4px 30px 0 rgba(35, 63, 169, 0.45);
            background-color: #fff;
            position: absolute;
            top: 84px;
            left: -8px;
            z-index: 20;
            box-sizing: border-box;
            padding: 16px;
            h3 {
                font-size: 16px;
                line-height: 1.5;
                color: #000;
                margin-bottom: 8px;
            }
            p {
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                line-height: 1.5;
                color: #000000;
            }
            .slide-tooltip-icon {
                width: 56px;
                margin-right: 24px;
            }
            .slide-tooltip-close {
                width: 12px;
                position: absolute;
                top: 12px;
                right: 12px;
                box-sizing: content-box;
                padding: 4px;
            }
        }
    
    }

    &__edit-block {
        max-height: 480px;
        flex-grow: 1;
        flex-shrink: 0;
        position: relative;
        width: calc(100% + 20px);
        max-width: 644px;
        &:after {
            pointer-events: none;
            content: '';
            display: block;
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            height: 64px;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(255, 255, 255, .8) 60%);
        }
    }
    &__edit-container {
        height: 100%;
        overflow: auto;
        &::-webkit-scrollbar-track {
            border-radius: 8px;
            background-color: transparent;
        }
    
        &::-webkit-scrollbar {
            width: 4px;
            background-color: transparent;
        }
    
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: $color-grey;
        }
    }
    &__pending-block {
        padding: 0 20px;
        margin-bottom: 20px;
    }
}

.manage-page__collage-block-processing {
    &.mobile {
        display: none;
    }
    &.desktop {
        display: block;
    }
}

.pending-block {
    margin-bottom: 24px;
    &__title {
        font-family: "Poppins-Regular", sans-serif;
        font-size: 24px;
        line-height: 1;
        margin-bottom: 16px;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 138px;
        height: 138px;
        border-radius: 12px;
        box-shadow: 0 4px 20px 0 $box-shadow-color;
        border: solid 1px $color-grey;
        overflow: hidden;
        position: relative;
        margin-bottom: 16px;
        margin-right: 16px;
        &:nth-child(4n) {
            margin-right: 0;
        }
        &:after {
            content: "";
            opacity: 0;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transition: .6s;
            background-color: rgba(0, 0, 0, .2);
        }
        &:hover {
            &:after {
                opacity: 1;
            }
            .pending-block__image {
                filter: blur(5px);
            }
            .pending-block__btn {
                opacity: 1;
            }
        }
    }
    &__btn {
        opacity: 0;
        width: 32px;
        height: 32px;
        border-radius: 6px;
        background-color: rgba(255, 255, 255, 0.75);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .6s;
        position: relative;
        z-index: 5;
        margin: 0 9px;
        &-icon {
            height: 16px;
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        transition: .6s;
        position: absolute;
        top: 0;
        left: 0;
    }
}

/*copied-block*/
.copied-block {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    max-width: 400px;
    width: 90%;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 4px 30px 0 $box-shadow-color;
    background-color: $color-black;
    animation: hide 2s ease-in 3s;
    position: fixed;
    z-index: 1000;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    &__icon {
        width: 56px;
        height: 56px;
        margin-right: 24px;
    }
    &__content {
        font-size: 16px;
        color: $color-white;
    }
    &__title {
        line-height: 1.5;
        margin-bottom: 8px;
    }
    &__text {
        font-family: "Poppins-Regular", sans-serif;
        line-height: 1.5;
    }
    &__btn-close {
        position: absolute;
        right: 10px;
        top: 10px;
        display: flex;
        background-color: transparent;
        padding: 6px;
    }
    &__icon-close {
        width: 12px;
        height: 12px;
    }
}

@keyframes hide {
    100% {
        opacity: 0;
    }
}

.caption-form {
    margin-bottom: 24px;
    &__title {
        font-size: 14px;
        line-height: 1.43;
        margin-bottom: 8px;
    }
    &__container {
        height: 56px;
        display: flex;
        align-items: center;
    }
    &__input {
        border-radius: 12px;
        border: solid 2px #ebebeb;
        height: 100%;
        flex-grow: 1;
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 8px;
    }
    &__button {
        flex-shrink: 0;
    }
}

.owner-mob {
    display: none;
}

/*attach-page*/
.attach-page {
    .portraits__title-container {
        flex-direction: column;
        margin-right: 24px;
        button {
            margin-top: 16px;
        }
    }
    &.attach-result {
        .portraits__title-container {
            justify-content: flex-start;
            button {
                margin-top: 0;
            }
        }
        .portraits__title {
            margin-bottom: 24px;
        }
        p {
            font-family: "Poppins",sans-serif;
            font-size: 24px;
            line-height: 1.67;
            margin-bottom: 48px;
        }
        .form__email {
            p {
                font-size: 16px;
                line-height: 1.5;
                color: #c7c7c7;
                margin: 0;
            }
            button {
                margin-top: 24px;
            }
        }
        .form__email-container  {
            margin-bottom: 8px;
        }
        .form__email-error-message {
            position: relative;
            top: 7px;
        }
        .btns-container-desktop {
            position: relative;
            padding-top: 50px;
            margin-top: 50px;
            &::before {
                content: "";
                display: block;
                height: 1px;
                background-image: linear-gradient(to right, #cdcdcd, rgba(205, 205, 205, 0));
                width: 50%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .form-message {
            display: flex;
            align-items: center;
            .copied-block__icon {
                fill: none;
                margin-right: 16px;
            }
            p {
                font-size: 16px;
                line-height: 1.5;
                margin: 0;
            }
        }
    }
    .btns-container-mob {
        display: none;
    }
    .portraits__container {
        align-items: flex-start;
    }
    .portraits__preview-image {
        max-height: 100%;
    }
    .image-warning-tooltip {
        bottom: 0;
        max-width: 100%;
        .triangle {
            display: none;
        }
    }
    @media all and (max-width: 950px) {
        .portraits__image-container {
            max-width: 400px;
            display: flex;
            justify-content: center;
        }
        .portraits__preview-image {
            width: 100%;
            max-width: 70%;
            max-height: initial;
        }
        .portraits__title-container p {
            margin-bottom: 0;
        }
        &.attach-result {
            .portraits__preview-image {
                max-width: 30%;
            }
            .btns-container-desktop {
                padding-top: 24px;
                margin-top: 24px;
            }
        }
    }
    @media all and (max-width: 768px) {
        .portraits__image-container {
            max-width: 366px;
        }
    }
    @media all and (max-width: 450px) {
        .portraits__preview-image {
            max-width: 50%;
        }
        &.attach-result {
            .portraits__container {
                align-items: center;
            }
            .portraits__preview-image {
                max-width: 50%;
            }
        }
    }

    .logo-container {
        margin-bottom: 48px;

        @media all and (max-width: 768px) {
            margin-bottom: 40px;
            .logo {
                margin-bottom: 0;
            }
        }

        @media all and (max-width: 450px) {
            margin-bottom: 24px;
            .logo {
                margin-bottom: 0;
            }
        }
    }
}

.download-page {
    .btns-container-desktop {
        .btn_download {
            display: none;
        }
    }
}

.app-webview {
    .contact-link {
        display: none;
    }
    .main-page__bg {
        margin-top: 0;
    }
}

.manage-container {
    border-radius: 12px;
    box-shadow: 0 4px 15px 0 rgba(35, 63, 169, .15);
    background-color: #fff;
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px 22px;
    margin-top: 16px;
    margin-bottom: 16px;
    button {
        display: flex;
        position: absolute;
        top: 4px;
        right: 4px;
        width: 24px;
        height: 24px;
        background-color: transparent;
        padding: 4px;
        svg {
            width: 100%;
        }
    }
    .manage-container__text {
        font-family: "Poppins", sans-serif;
        line-height: 1.5;
        text-align: left;
        color: #000;
        margin: 0;
    }
    &__icon {
        width: 36px;
        flex-shrink: 0;
        margin-right: 22px;
    }
    a {
        font-family: "Poppins-SemiBold", sans-serif;
        color: #3758d6;
        text-transform: uppercase;
        // margin-left: 4px;
    }
}

.caption-container {
    display: flex;
    justify-content: center;
    button {
        font-family: "Poppins-SemiBold", sans-serif;
        line-height: 1.5;
        letter-spacing: 0.64px;
        text-transform: uppercase;
        color: #3758d6;
        display: flex;
        align-items: center;
        background-color: transparent;
        margin-bottom: 16px;
    }
    svg {
        width: 24px;
        margin-right: 12px;
    }
}

/*choose-body*/
.choose-body {
    .title-h2 {
        font-size: 28px;
        text-align: center;
        margin-bottom: 16px;
    }
    .modal__content-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        overflow: hidden;
        left: 0;
        padding: 0;
    }
    .btn-select {
        font-family: "Poppins-SemiBold", sans-serif;
        font-size: 24px;
        line-height: 1;
        letter-spacing: 0.96px;
        text-transform: uppercase;
        color: #fff;
        width: 160px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        flex-shrink: 0;
        border-radius: 16px;
        background-color: #6cd899;
        margin: 0 auto;
        svg {
            width: 24px;
            display: none;
        }
    }
    .proccesing {
        height: 100%;
        max-width: 480px;
        width: 100%;
        position: relative;

        .loader {
            position: static;
        }
    }
    .error-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
            text-align: center;
            line-height: 1.2;
            margin-bottom: 16px;
        }
        button {
            font-family: "Poppins-SemiBold",sans-serif;
            font-size: 18px;
            line-height: 1;
            letter-spacing: .96px;
            text-transform: uppercase;
            color: #fff;
            width: 130px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 0;
            flex-shrink: 0;
            border-radius: 16px;
            background-color: #6cd899;
            margin: 0 auto;
        }
    }
}

.creative-block {
    height: 100%;
    min-height: 200px;
    width: 100%;
    max-width: 480px;
}

.choose-body-btns {
    margin-bottom: 16px;
    button {
        font-size: 20px;
        line-height: 1;
        letter-spacing: 0.96px;
        text-transform: uppercase;
        width: 50%;
        background: no-repeat;
        border-bottom: 1px solid #a2a2a2;
        padding-bottom: 8px;
    }
    .active {
        font-family: "Poppins-SemiBold", sans-serif;
        color: #3758d6;
        border-bottom: 1px solid #3758d6;
        padding-bottom: 7px;
    }
}

.choose-body-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    max-height: 480px;
    height: 100%;
    margin-bottom: 16px;
    .creative-container {
        height: 100%;
        display: flex;
        justify-content: center;
    }
    .creative {
        height: 100%;
        object-fit: contain;
        max-width: 480px;
        border-radius: 16px;
    }
}

.choose-body-templates {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    overflow-y: auto;
    height: 100%;
    padding-right: 10px;
    margin-right: 16px;
    button {
        width: 72px;
        height: 72px;
        flex-shrink: 0;
        border-radius: 8px;
        background-size: cover;
        margin-bottom: 8px;
    }
    .active {
        border: solid 4px #3758d6;
    }
    @media all and (min-width: 771px) {
        &::-webkit-scrollbar-track {
            border-radius: 8px;
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: 4px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: $color-grey;
        }
    }
}

@media all and (max-height: 865px) and (min-width: 992px) and (orientation: landscape) {
    .main-slider {
        max-width: 450px;
        top: 56%;
        img {
            max-height: 450px;
        }
    }
    .main-page__content {
        width: 54%;
    }
    .logo-container {
        margin-bottom: 3.5vh;
    }
    .main-section.main-page {
        .main-page__title {
            margin-bottom: 16px;
        }
        .main-page__text {
            margin-bottom: 30px;
        }
    }
}

@media all and (max-height: 700px) and (min-width: 1150px) and (orientation: landscape) {
    .logo-container {
        margin-bottom: 3.5vh;
    }
    .main-section.main-page {
        .main-page__title {
            margin-bottom: 16px;
        }
        .main-page__text {
            margin-bottom: 30px;
        }
    }
    .main-slider {
        max-width: 450px;
        top: 56%;
        img {
            max-height: 450px;
        }
    }
}

@media all and (max-width: 770px) {
    .choose-body-content {
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        max-height: 100%;
        margin: 0;
    }
    .choose-body-templates {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: auto;
        overflow: hidden;
        overflow-x: auto;
        width: 100%;
        padding-bottom: 10px;
        margin: 0;
        margin-top: 44px;
        margin-bottom: -6px;
    }
    .choose-body-templates button {
        margin-bottom: 0;
        margin-right: 4px;
    }
    .modal__container .choose-body .title-h2 {
        font-size: 20px;
        margin-top: 10px;
    }
    .choose-body-btns button {
        font-size: 16px;
    }
    .choose-body-content .creative-container {
        height: 100%;
        width: 100%;
        flex-grow: 1;
        position: relative;
    }
    .choose-body-content .creative {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .choose-body .btn-select {
        position: absolute;
        right: 0;
        bottom: 88px;
        width: 56px;
        height: 56px;
        font-size: 0;
        border-radius: 50%;
        svg {
            display: block;
        }
        span {
            display: none;
        }
    }
}

@media all and (max-width: 1185px) {
    .manage-page__slider {
        .slick{
            &-prev {
                left: calc(50% - -20px);
            }
            &-next {
                right: 20px;
            }
        }
    }
    .manage-page__slide .slide-tooltip {
        max-width: calc(100% - 40px);
        top: 30px;
        left: 20px;
        z-index: 20;
    }

    .result-page .manage-page__slide .slide-tooltip {
        max-width: calc(100% - 40px);
        top: 30px;
        left: 20px;
        z-index: 20;
    }
}

@media all and (max-width: 1170px) {
    .manage-page {
        &__collage-block {
            // max-width: 400px;
        }
        &__edit-block {
            flex-shrink: 1;
        }
    }
    .pending-block__item {
        width: 30%;
        height: 100%;
        margin-right: 3%;
        &:nth-child(4n) {
            margin-right: 3%;
        }
        &:before {
            content: '';
            display: block;
            padding-top: 100%;
        }
    }
}

@media all and (max-width: 1070px) {
    .portraits__item:nth-child(4n) {
        margin-right: 24px;
    }
    .result-page {
        &__action {
            &-btns {
                & > div {
                    flex-direction: column;
                }
                & > div {
                    width: 100%;
                }
                &.result-page_collage-owner {
                    flex-direction: row;
                    .result-page__action-btn {
                        border: none;
                        height: auto;
                        margin: 0;
                    }
                }
                .btn {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 16px;
                }
            }
            &-btn {
                border: 2px solid $color-blue-dark;
                height: 56px;
                width: 100%;
                max-width: 100%;
            }
        }
        &__moderate-btn {
            max-width: 100%;
            width: 100%;
            margin: 0;
            margin-top: 16px;
        }
        &__column_2 {
            width: 100%;
            margin-left: 30px;
        }
        .logo {
            margin-bottom: 40px;
        }
        &-btn_upload-collage-owner {
            display: block;
        }
    }
    .manage-page {
        &__title-block {
            max-width: 500px;
        }
        &__collage-block {
            max-width: 400px;
        }
    }
    .portraits_preview {
        .logo {
            margin-bottom: 24px;
        }
    }
}

@media all and (max-width: 1030px) and (orientation: portrait) {
    .main-page {
        &__content {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
}



@media all and (max-width: 991px) {
    .main-section {
        overflow-x: hidden;
        padding-top: 24px;
        padding-bottom: 20px;
    }
    .title-h2 {
        font-size: 40px;
        line-height: 1.4;
    }
    .main-page {
        &__content {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
        &__title {
            margin-bottom: 8vh;
        }
        &__bg {
            top: 0;
            left: 0;
            right: 0;
            transform: none;
        }
    }
    .form {
        flex-direction: column;
        &__title {
            max-width: 100%;
        }
        &__column_first {
            margin-right: 0;
        }
    }
}

@media all and (max-width: 950px) {
    .result-page {
        &__content {
            flex-direction: column;
            padding-bottom: 0;
            &.guest {
                grid-template-columns: 1fr;
                .result-page__column_1 {
                    margin-bottom: 40px;
                }
                .result-page__column_1-2 {
                    grid-row: 3/4;
                }
                .result-page__column_2 {
                    grid-row: 2/3;
                    grid-column: 1/2;
                    align-items: flex-start;
                }
                .result-page__column_2 > div {
                    display: flex;
                    flex-direction: column-reverse;
                }
            }
            &.owner {
                .result-page__column_1 {
                    margin-bottom: 16px;
                }
                .result-page__column_2 > div {
                    display: flex;
                    flex-direction: column;
                    .result-page__collage-title-container {
                        order: 2;
                    }
                    .result-page__action-btns {
                        order: 3;
                    }
                }
            }
        }
        &__column {
            &_1 {
                max-width: 100%;
                padding-right: 0;
                margin-top: 0;
                margin-bottom: 40px;
                .share-container {
                    &__btn {
                        margin: 0;
                        margin-left: 8px;
                    }
                }
                .result-page__action-btn {
                    margin-right: 24px;
                    &::before {
                        right: -16px;
                    }
                }
            }
            &_2 {
                margin: 0;
                .manage-page__slider .slick-next {
                    right: 6px;
                    left: auto;
                }
            }
        }
    }
    .attach-page {
        .portraits__title-container {
            margin-right: 0;
            margin-bottom: 16px;
        }
    }
    .owner-desktop {
        display: none;
    }
    .owner-mob {
        display: block;
        &.caption-form{
            margin-top: 40px;
        }
    }
    .portraits__title {
        max-width: 100%;
    }
    .portraits__container {
        flex-direction: column;
        align-items: flex-start;
        .btn {
            display: block;
        }
    }
    .portraits_preview .portraits__title-container {
        margin-bottom: 0;
    }
    .manage-page__collage-block-processing {
        &.mobile {
            display: block;
        }
        &.desktop {
            display: none;
        }
    }

    .manage-page {
        &__collage-block {
            max-width: 480px;
            width: calc(100% + 40px);
            margin-left: -20px;
        }
        &__slider {
            position: relative;
            top: 0;
            right: 0;
            width: 100%;
            margin-left: 0;
            margin-bottom: 0;
            .slick-slide {
                opacity: 1;
            }
            .slick-next {
                right: 6px;
            }
            .slick-prev {
                left: 6px;
            }
        }
        &__content .caption-form,
        &__btns-container {
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }
        .caption-form {
            margin-bottom: 0;
        }
    }
}

@media all and (max-width: 910px) {
    .portraits__item {
        width: 175px;
        height: 175px;
        margin: 0 16px 16px 0;
    }
    .manage-page {
        .logo {
            margin-bottom: 40px;
        }
        &__edit-block {
            max-width: initial;
            width: calc(100% + 40px);
            margin-left: -20px;
            &:after {
                content: none;
            }
        }
        &__content {
            flex-direction: column;
        }
        &__collage-block {
            max-width: 480px;
            margin: 0;
            margin-bottom: 40px;
        }
        &__preview {
            flex-direction: column;
            margin-bottom: 40px;
        }
        &__title-block {
            max-width: 100%;
            margin-bottom: 16px;
        }
        &__btns-block {
            align-items: flex-start;
            &-create {
                margin-bottom: 24px;
                .btn {
                    margin-left: 0;
                    margin-bottom: 16px;
                    display: block;
                }
            }
        }
        .share-container_small {
            .share-container {
                &__title {
                    text-align: left;
                }
                &__btn {
                    margin-left: 0;
                    margin-right: 8px;
                    &-download-small {
                        margin-right: 32px;
                        &::before {
                            right: -16px;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}

@media all and (max-width: 870px) {
    .portraits__title-container {
        flex-direction: column;
        margin-bottom: 24px;
    }
    .portraits__title {
        margin-bottom: 16px;
    }
    .main-page2 .main-page__content-container {
        flex-direction: column-reverse;
    }
    .main-page2 .main-page__bg {
        position: relative;
        max-width: 400px;
        left: 50%;
        transform: translateX(-50%);
    }
    .main-page2 .main-page__content {
        width: 100%;
    }
}

@media all and (max-width: 768px) {
    .caption-form__container {
        height: 64px;
    }
    .caption-form__input {
        border-radius: 8px;
    }
    .btn {
        max-width: 366px;
        height: 64px;
        border-radius: 8px;
        &_height-big {
            height: 80px;
        }
    }

    .form {
        &__container {
            flex-direction: column;
        }
        &__title-block {
            position: static;
        }
        &__tooltip-btn {
            position: absolute;
            right: 24px;
            top: 24px;
            left: auto;
        }
        &__title {
            margin-bottom: 16px;
            &-input {
                font-size: 14px;
            }
        }
        &__subtitle {
            font-size: 16px;
        }
        &__column{
            &_first {
                margin-bottom: 32px;
            }
            &_second {
                padding: 0;
                max-width: 100%;
            }
            &-container {
                flex-direction: column-reverse;
            }
            &-info {
                width: 100%;
            }
        }
        &__input {
            height: 64px;
            line-height: 64px;
        }
        &__email-error-message {
            font-size: 14px;
            top: 72px;
        }
        &__email-container {
            margin-bottom: 32px;
        }
        &__type-container {
            margin-bottom: 32px;
        }
        &__label {
            &-name {
                font-size: 16px;
            }
            &-subname {
                font-size: 14px;
                position: relative;
                top: -7px;
            }
            &:first-child {
                margin-bottom: 8px;
            }
        }
        &__number-block {
            position: relative;
        }
        &__number {
            width: 64px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            &-btn-block {
                flex-direction: row;
                width: 366px;
            }
        }
        .btn_ex-small {
            font-size: 24px;
            letter-spacing: 0.64px;
            width: 143px;
            height: 64px;
        }
        &__preview {
            margin: 0;
            margin-top: 24px;
        }
        &__avatar-container {
            align-items: stretch;
            width: 100%;
            background-color: transparent;
            padding: 0;
        }
    }
    .logo {
        margin-bottom: 40px;
    }
    
    .title-h2 {
        font-size: 36px;
        line-height: 1.33;
    }
    .result-page {
        &__action-btn,
        &__moderate-btn {
            max-width: 100%;
        }
    }

    .collage-type {
        &__list {
            flex-wrap: wrap;
            justify-content: space-between;
        }
        &__item {
            width: 47%;
            margin: 0;
            margin-bottom: 16px;

        }
    }
    
    .download-page {
        .btns-container-desktop .btn_download {
            display: block;
            height: auto;
            margin-bottom: 40px;
        }
        .portraits__title-container {
            .btn_download {
                display: none;
            }
        }
        &.attach-page.attach-result p {
            margin-bottom: 0;
        }
    }
}

@media all and (max-width: 600px) {
    .modal__collage {
        width: calc(100% + 60px);
        &-item {
            width: 48%;
        }
    }
    .loader_small .loader__text {
        font-size: 14px;
        width: 100%;
    }

    .creative-block {
        height: 78%;
    }

    .manage-page {
        &__collage-block {
            max-width: initial;
            width: calc(100% + 40px);
            margin-left: -20px;
        }
        &__slider {
            position: relative;
            top: 0;
            right: 0;
            width: 100%;
            margin-left: 0;
            margin-bottom: 0;
            .slick-slide {
                opacity: 1;
            }
            .slick-next {
                right: 6px;
            }
            .slick-prev {
                left: 6px;
            }
        }
        &__content .caption-form,
        &__btns-container {
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }
        .caption-form {
            margin-bottom: 0;
        }
    }

    .result-page__column_2 {
        max-width: 100%;
        .manage-page {
            &__collage-block {
                max-width: initial;
                width: calc(100% + 40px);
                margin-left: -20px;
                margin-bottom: 0;
            }
            &__slider {
                position: relative;
                top: 0;
                right: 0;
                width: 100%;
                margin-left: 0;
                .slick-slide {
                    opacity: 1;
                }
                .slick-next {
                    left: auto;
                    right: 6px;
                }
                .slick-prev {
                    left: 6px;
                }
            }
        }    
    }

    .modal__container {
        padding: 24px;
        .title-h2 {
            font-size: 28px;
        }
    }
    .modal__text {
        font-size: 18px;
        margin-bottom: 24px 
    }
    .form__type-block {
        margin-bottom: 24px;
    }
}

@media all and (max-width: 450px) {
    .caption-form {
        &__container {
            flex-direction: column;
            height: auto;
        }
        &__input {
            width: 100%;
            height: 64px;
            margin: 0;
            margin-bottom: 16px;
        }
        .caption-form__button {
            font-size: 16px;
            height: 48px;
        }
    }
    .main-page2 {
        overflow: auto;
        .main-page {
            &__text {
                margin-bottom: 35px;
            }
            &__content {
                margin-top: -35px;
            }
            &__bg {
                top: 0;
                margin-top: 0;
            }
            &__content-container {
                padding-bottom: 20px;
            }
        }
        .logo {
            margin-bottom: 0;
        }
    }
    .main-page3 {
        .main-page__bg {
            left: -20px;
            transform: none;
            margin-top: 16px;
        }
    }
    .main-page2 {
        .main-page__title {
            margin-bottom: 8px;
        }
        .main-page__content {
            margin-top: -60px;
        }
        .main-page__text {
            order: 3;
            margin-bottom: 0;
        }
        .main-page__btn {
            font-size: 20px;
            letter-spacing: .8px;
            line-height: 1;
            height: 64px;
            padding: 0;
            margin-bottom: 10px;
        }
    }
    .main-page__bg {
        top: 52px;
    }
    .btn {
        max-width: 100%;
    }
    .title-h2 {
        font-size: 24px;
    }
    .container {
        padding-left: 20px;
        padding-right: 20px;
    }
    .main-page {
        padding-bottom: 20px;
        &__title {
            margin-bottom: 16px;
        }
        &__text {
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 56px;
        }
        &__bubble1 {
            width: 64px;
            height: 64px;
            top: 60px;
            left: 50%;
            transform: translateX(-50%);
        }
        &__bubble2 {
            top: 35%;
            left: 58%;
            width: 80px;
            height: 80px;
        }
        &__bubble4 {
            top: 48%;
            left: 0%;
        }
        &__bubble3,
        &__bubble5 {
            display: none;
        }
        &__btn {
            line-height: 1.33;
            padding: 0 15vw;
        }
    }
    .modal {
        &__container {
            padding: 16px;
        }
        &__image {
            margin-bottom: 4.5vh;
            &_desktop {
                display: none;
            }
            &_mob {
                display: block;
            }
        }
        &__title {
            margin-bottom: 16px;
        }
        &__text {
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 32px;
        }
        &__bubble1 {
            width: 200px;
            height: 200px;
            left: 40px;
        }
    }
    .main-section {
        padding-top: 20px;
    }
    .logo {
        margin-bottom: 24px;
    }
    .form {
        &__column {
            &_first {
                margin-bottom: 24px;
            }
        }
        &__title {
            margin-bottom: 12px;
        }
        &__tooltip-btn {
            right: 16px;
            top: 16px;
        }
        &__email-container {
            margin-bottom: 24px;
        }
        &__email-error-message {
            font-size: 12px;
            top: 82px;
        }
        &__input {
            font-size: 20px;
            height: 48px;
            line-height: 48px;
            display: flex;
            align-items: center;
            padding: 0 16px;
        }
        &__type-container {
            margin-bottom: 24px;
        }
        &__title-input {
            margin-bottom: 12px;
        }
        &__preview-image-container {
            width: 280px;
            &_show {
                height: 280px;
            }
        }
        &__number {
            font-size: 20px;
            width: 48px;
            height: 48px;
            padding: 0 6px;
            &-btn-block {
                max-width: 320px;
            }
        }
        &__avatar {
            &-content {
                flex-direction: column;
                align-items: flex-start;
            }
            &-block {
                width: 100%;
                max-width: 340px;
                height: auto;
                position: relative;
                margin: 0 auto 16px;
                &::before {
                    content: "";
                    display: block;
                    padding-top: 100%;
                }
            }
            &-container {
                .btn {
                    max-width: 100%;
                    font-size: 20px;
                }
            }
            &-image {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .btn_ex-small {
            font-size: 20px;
            letter-spacing: 0.8px;
            width: 128px;
            height: 48px;
        }
    }
    .portraits__list {
        justify-content: space-between;
    }
    .portraits__item {
        margin-right: 0;
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
    .portraits__title-container {
        .btn {
            background-color: $color-white;
            position: fixed;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
            max-width: 360px;
            width: 90%;
        }
        p {
            font-size: 22px;
            line-height: 1.33;
            margin-bottom: 16px;
        }
    }
    .portraits__container {
        .portraits__title-container {
            .btn {
                position: static;
                transform: none;
                width: 100%;
                max-width: 100%;
                &.btn_paint {
                    background-color: $color-blue-dark;
                }
            }
        }
    }
    .image-warning-tooltip {
        bottom: 0;
        max-width: 100%;
        background-color: rgba(251, 226, 0, .85);
        .triangle {
            display: none;
        }
    }
    .result-page {
        &__title {
            margin-bottom: 16px;
        }
        &__text {
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 16px;
            margin-top: 0;
        }
        &__collage {
            margin-bottom: 56px;
        }
        .logo {
            margin-bottom: 24px;
        }
        &__content{
            &.guest {
                .result-page__collage {
                    transform: rotate(2deg);
                    margin-bottom: 18px;
                }
                .result-page__collage-title {
                    font-size: 14px;
                    line-height: 1.43;
                    text-align: center;
                    margin-bottom: 4px;
                }
                .result-page__collage-subtitle {
                    font-size: 14px;
                    line-height: 1.43;
                    text-align: center;
                    margin-bottom: 0;
                }
            }
            &.owner {
                .result-page__collage-title {
                    font-size: 14px;
                    line-height: 1.43;
                    text-align: center;
                    margin-bottom: 4px;
                }
                .result-page__collage-subtitle {
                    font-size: 14px;
                    line-height: 1.43;
                    text-align: center;
                    margin-bottom: 24px;
                }
                .imageview-host {
                    margin-bottom: 8px;
                }
            }
            &-container {
                .result-page {
                    &__text {
                        line-height: 1.5;
                    }
                }
            }
        }
    }
    .result-page__column_1 {
        .share-container {
            .result-page__action-btn {
                width: 64px;
                height: 64px;
            }
        }
    }
    .result-page__action-btns {
        width: 100%;
        margin-bottom: 16px;
        margin-top: 16px;
    }
    .share-container {
        &__title,
        &__title-mob {
            font-size: 16px;
            margin-bottom: 8px;
        }
        &__btn {
            width: 64px;
            height: 64px;
            margin-right: 8px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .manage-page {
        .logo {
            margin-bottom: 24px;
        }
        &__text {
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 0;
        }
        .manage-page__btns-block-create {
            width: 100%;
            margin-bottom: 16px;
            .btn {
                max-width: 100%;
                width: 100%;
            }
        }
        &__preview {
            margin-bottom: 24px;
        }
        &__collage-block {
            margin-bottom: 24px;
        }
    }
    .modal__collage {
        width: calc(100% + 20px);
    }
    .create-page {
        padding-bottom: 20px;
        &.portraits {
            padding-bottom: 100px
        }
    }
    .attach-page.attach-result {
        .portraits__title {
            margin-bottom: 0;
        }
        p {
            font-size: 20px;
            line-height: 1.5;
            margin-bottom: 24px;
        }
        .form__email p {
            font-size: 14px;
        }
        .form__email-error-message {
            top: 4px;
        }
        .form__email button {
            width: 280px;
            height: 48px;
            font-size: 16px;
            margin: 16px auto 0;
        }
        .portraits__title-container {
            margin-bottom: 24px;
        }
        .btns-container-desktop {
            padding-top: 0;
            margin-top: 24px;
            &::before {
                content: none;
            }
        }
    }

    .main-button-below-text.main-page-slider .btn_width-big {
        order: 5;
        margin-bottom: 0;
    }
}

@media all and (max-width: 400px) {
    .portraits__item {
        width: 134px;
        height: 134px;
    }
}

@media all and (max-width: 380px) {
    .btn {
        font-size: 20px;
        letter-spacing: .8px;        
    }
    .main-page {
        &__title {
            font-size: 28px;
            margin-bottom: 12px;
        }
        &__text {
            margin-bottom: 24px;
        }
        &__btn {
            line-height: 1;
            height: 64px;
            padding: 0;
        }
    }
    .result-page__action-btns.result-page_collage-owner {
        flex-direction: column;
        align-items: stretch;
        .share-container {
            &_small {
                .share-container__title {
                    text-align: left;
                }
                .share-container__btn {
                    margin-left: 0;
                    margin-right: 8px;
                }
            }
        }
    }
    .result-page__action-btns.result-page_collage-owner .result-page__action-btn {
        border: 2px solid $color-blue-dark;
        height: 56px;
        margin-bottom: 16px;
    }
}

@media all and (max-width: 362px) {
    .main-page {
        .logo {
            margin-bottom: 0;
        }
        &__bg {
            top: 40px;
        }
    }
    .form {
        &__number-container {
            width: 100%;
        }
        &__number-btn-block {
            max-width: 100%;
            width: 100%;
        }
        .btn_ex-small {
            width: 40%;
        }
    }
}

@media all and (max-width: 350px) {
    .btn {
        font-size: 18px;
    }
    .main-page__title {
        font-size: 24px;
    }   
    .result-page__action-btn-icon {
        margin-right: 6px;
    }
    .manage-page__slide .slide-caption {
        justify-content: space-between;
    }
    .manage-page__slide .slide-caption span {
        flex-shrink: 0;
    }
    .manage-page__slide .slide-caption svg {
        flex-shrink: 0;
        margin: 0;
    }
}

@media all and (max-width: 330px) {
    .portraits__title {
        margin-bottom: 8px;
    }
    .portraits__title-container p {
        font-size: 20px;
        margin-bottom: 8px;
    }
}

@media all and (max-height: 700px) and (max-width: 640px) {
    .main-page__bg {
        top: 0;
    }
}

@media all and (max-height: 640px) and (max-width: 640px) {
    .main-page__bg {
        top: 24px;
        left: 50%;
        transform: translateX(-50%);
        width: 60%;
    }
    .main-page__text {
        margin-bottom: 40px;
    }
}
@media all and (max-height: 540px) and (max-width: 320px) {
    .portraits__title-container .btn {
        height: 48px;
        font-size: 16px;
    }
    .portraits__title-container p {
        font-size: 18px;
    }
}